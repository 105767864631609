import * as R from "ramda";
import { TaxBreakdownTable } from "../TaxBreakdownTable";
import { useReceiptOrder } from "../../ReceiptContext";
import { TaxBreakdownTableData } from "../types";
import { translate } from "src/lib/i18n";
import { formatTaxName } from "src/lib/utils";
import { useCurrencyFormatter } from "src/lib/hooks/useCurrencyFormatter";
import { TaxBreakdownBlockSettings } from "../structure";
import { CurrencyFormat } from "../../../../../../shared/interface";

export type ProductTaxBreakdownBlockProps = {
  blockSettings: TaxBreakdownBlockSettings
}

export const ProductTaxBreakdownBlock = (props: ProductTaxBreakdownBlockProps) => {
  const order = useReceiptOrder()

  const taxBreakdownItems = R.values(order.taxBreakdownTableData ?? {});
  const taxBreakdownTable = useTaxBreakdownTable(order, taxBreakdownItems, props.blockSettings.currencyFormat);

  return <TaxBreakdownTable data={taxBreakdownTable} />;
};


export const useTaxBreakdownTable = (
  order: ApiOrderDetail,
  taxBreakdownItems: TaxBreakdown[],
  currencyFormat?: CurrencyFormat
): TaxBreakdownTableData => {
  const {formatCurrency} = useCurrencyFormatter(order.currencyCode, currencyFormat)

  const longestRateLength = R.reduce<number, number>(
    R.max,
    0,
    taxBreakdownItems?.map(({ rate }) => rate?.length ?? 0) ?? []
  );
  const headers = [
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.rate") as string,
    },
    ...(taxBreakdownItems
      .map(({ name, rate }) => ({
        value: formatTaxName(name, rate ?? "", longestRateLength),
      }))
      .values() ?? []),
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.total") as string,
    },
  ];
  const taxes = [
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.tax") as string,
    },
    ...(taxBreakdownItems
      .map(({ taxTotal }) => ({
        value: formatCurrency(taxTotal),
      }))
      .values() ?? []),
    {
      value: formatCurrency(order.totalTax),
    },
  ];

  const netto = [
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.netto") as string,
    },
    ...(taxBreakdownItems
      .map(({ subtotal }) => ({
        value: formatCurrency(subtotal),
      }))
      .values() ?? []),
    {
      value: formatCurrency(order.subtotalPrice ?? ''),
    },
  ];

  const brutto = [
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.brutto") as string,
    },
    ...(taxBreakdownItems
      .map(({ total }) => ({
        value: formatCurrency(total),
      }))
      .values() ?? []),
    {
      value: formatCurrency(order.totalPrice),
    },
  ];

  return {
    headers, netto, taxes, brutto,
  }
};