import { Text } from "react-native";
import { TextBlockSettings } from "../structure";
import { styles as parentStyles } from "../styles";
import { translate } from "src/lib/i18n";

export const TextBlock = (props: { blockSettings: TextBlockSettings }) => {
  const { blockSettings: blockProps } = props;
  const { content, textAlign } = blockProps;

  let text;
  if (content.type === "translation") {
    text = translate(content.translationKey) as string;
  } else {
    text = content.text;
  }

  return (
    <div style={{padding: '0 8px', ...(textAlign === "center" && { textAlign: "center" }) }}>
      <Text style={parentStyles.title}>{text}</Text>
    </div>
  );
};
