import React from "react";

import { Image } from "react-native";
import { styles } from "./styles";

export type ImageLogoProps = {
  uri: string;
};

export const ImageLogo = (props: ImageLogoProps) => (
  <Image
    resizeMode="contain"
    style={styles.image}
    source={{ uri: props.uri }}
  />
);
