import { useContext } from "react";
import { MasterContext } from "../masterContext";
import { currencyMap } from "../constants";
import { CurrencyFormat } from "../../../../shared/interface";

export function useCurrencyFormatter(
  currencyCode: CurrencyCode,
  formatOverride?: CurrencyFormat
) {
  const { appConfig } = useContext(MasterContext);
  const currencyFormat = formatOverride ?? appConfig.currencyFormat;

  const formatCurrency = (amount: number | string) => {
    const plain = (
      typeof amount === "number" ? amount : parseFloat(amount)
    ).toFixed(2);

    if (currencyFormat === "plain") {
      return plain;
    }

    if (currencyFormat === "code") {
      return `${currencyCode} ${plain}`;
    } else {
      return `${currencyMap[currencyCode] || ""}${plain}`;
    }
  };

  return { formatCurrency };
}
