import React from "react";
import { Flavor } from "./flavor";
import { defaultFeatureFlags } from "./flavorTypes";
import { color2 } from "src/styles";
import { LogoZabka } from "src/components/LogoZabka";
import { currencyMap } from "../constants";
import { timezoneDate } from "../utils";

class ZabkaFlavor extends Flavor {
  getMainColor() {
    return color2.ZABKA;
  }

  formatCurrency(
    amount: string,
    currencyCode: CurrencyCode,
    quantity?: number
  ) {
    return `${quantity ? `${quantity} x ` : ""}${(
      parseFloat(amount) / (quantity || 1)
    ).toFixed(2)} ${currencyMap[currencyCode] || ""}`;
  }

  getEmptyReceiptContent() {
    return null;
  }

  getFeatureFlags() {
    return {
      ...defaultFeatureFlags,
      taxEnabled: true,
      hideDraftProducts: true,
      productsTaxEnabled: false,
      isFirstData: true,
      isCompanyEnabled: true,
      isAddressEnabled: true,
      aifiFooterText: false,
      isGroupShopping: false,
      isOrderDetailQrCodeEnabled: false,
      isStripeV2: false,
    };
  }

  getCircleLogo() {
    return <LogoZabka />;
  }

  getDefaultFontFamily() {
    return "TTCommons-Regular";
  }

  getPrimaryFont(): string {
    return this.getDefaultFontFamily();
  }

  getPrimaryFontLight(): string {
    return this.getDefaultFontFamily();
  }

  getSecondaryFont(): string {
    return this.getDefaultFontFamily();
  }

  getSecondaryFontBold(): string {
    return this.getDefaultFontFamily();
  }

  formatDateTime(dateTime: string, timezone: string) {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "DD.MM.YYYY";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }
}

const Singleton = new ZabkaFlavor();
export default Singleton;
