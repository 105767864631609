import React from "react";
import { defaultFeatureFlags, IFlavor } from "./flavorTypes";
import { LogoNewAiFi } from "../../components/LogoNewAiFi";
import { font } from "src/styles/themes";
import { currencyMap } from "../constants";
import { timezoneDate } from "../utils";
import { IFeatureFlags } from "../../../../shared/interface";

export abstract class Flavor implements IFlavor {
  getIconColor(): string {
    return this.getMainColor();
  }

  getSpinnerColor(): string {
    return this.getMainColor();
  }

  getDashLineColor(): string {
    return this.getMainColor();
  }

  formatCurrency(
    amount: string,
    currencyCode: CurrencyCode,
    _quantity?: number
  ): string {
    return `${currencyMap[currencyCode] || ""}${parseFloat(amount).toFixed(2)}`;
  }

  getFeatureFlags(): IFeatureFlags {
    return defaultFeatureFlags;
  }

  getCircleLogo(): JSX.Element {
    return <LogoNewAiFi />;
  }

  getReceiptLogo() {
    return this.getCircleLogo()
  }

  getPrimaryFont(): string {
    return font.family.montserrat;
  }

  getPrimaryFontLight(): string {
    return font.family.montserrat;
  }

  getPrimaryFontLightest(): string {
    return font.family.montserrat;
  }

  getSecondaryFont(): string {
    return "Montserrat";
  }

  getSecondaryFontBold(): string {
    return "Montserrat";
  }

  formatDateTime(dateTime: string, timezone: string): { date: string; time: string } {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "DD MMM YYYY";
    const formatTime = "hh:mma";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  formatTableDateTime(dateTime: string, timezone: string): { date: string; time: string } {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "DD/M/YY";
    const formatTime = "hh:mma";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  abstract getMainColor(): string;
}
