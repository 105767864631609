import { Flavor } from "./flavor";
import { timezoneDate } from "../utils";
import { config } from "../config";
import { ImageLogo } from "src/components/ImageLogo/ImageLogo";
import { color2 } from "src/styles";
import React from "react";

class EnvironmentFlavor extends Flavor {
  getCircleLogo() {
    if (config.environmentFlavorConfig?.logo?.uri) {
      return <ImageLogo uri={config.environmentFlavorConfig.logo.uri} />;
    }
    return <React.Fragment />;
  }

  getReceiptLogo() {
    return this.getCircleLogo();
  }

  getMainColor() {
    return config.environmentFlavorConfig?.colors?.main ?? color2.BLUE_DARK;
  }

  getIconColor() {
    return config.environmentFlavorConfig?.colors?.icon ?? super.getIconColor();
  }

  getSpinnerColor() {
    return (
      config.environmentFlavorConfig?.colors?.spinner ?? super.getSpinnerColor()
    );
  }

  getDashLineColor() {
    return (
      config.environmentFlavorConfig?.colors?.dashLine ??
      super.getDashLineColor()
    );
  }

  formatDateTime(
    dateTime: string,
    timezone: string
  ): { date: string; time: string } {
    const fallback = super.formatDateTime(dateTime, timezone);
    const tzDate = timezoneDate(dateTime, timezone);

    const dateFormat =
      config.environmentFlavorConfig?.formats?.defaultDateFormat;
    const date = dateFormat ? tzDate.format(dateFormat) : fallback.date;

    const timeFormat =
      config.environmentFlavorConfig?.formats?.defaultTimeFormat;
    const time = timeFormat ? tzDate.format(timeFormat) : fallback.time;

    return { date, time };
  }

  formatTableDateTime(
    dateTime: string,
    timezone: string
  ): { date: string; time: string } {
    const fallback = super.formatTableDateTime(dateTime, timezone);
    const tzDate = timezoneDate(dateTime, timezone);

    const dateFormat =
      config.environmentFlavorConfig?.formats?.tableDateFormat ??
      config.environmentFlavorConfig?.formats?.defaultDateFormat;
    const date = dateFormat ? tzDate.format(dateFormat) : fallback.date;

    const timeFormat =
      config.environmentFlavorConfig?.formats?.tableTimeFormat ??
      config.environmentFlavorConfig?.formats?.defaultTimeFormat;
    const time = timeFormat ? tzDate.format(timeFormat) : fallback.time;

    return { date, time };
  }

  getFeatureFlags() {
    const featureFlags = { ...super.getFeatureFlags() };

    if (!config.environmentFlavorConfig?.flags) {
      return featureFlags;
    }

    const configFlags = config.environmentFlavorConfig.flags;
    if (configFlags.hidePoweredByAiFi !== undefined) {
      featureFlags.hidePoweredByAiFi = configFlags.hidePoweredByAiFi;
    }
    if (configFlags.orderDetailsShowHeader !== undefined) {
      featureFlags.orderDetailsShowHeader = configFlags.orderDetailsShowHeader;
    }
    if (configFlags.orderDetailsShowDetailsAtTheTop !== undefined) {
      featureFlags.orderDetailsShowDetailsAtTheTop =
        configFlags.orderDetailsShowDetailsAtTheTop;
    }
    if (configFlags.orderDetailsShowProductsListTitle !== undefined) {
      featureFlags.orderDetailsShowProductsListTitle =
        configFlags.orderDetailsShowProductsListTitle;
    }
    if (configFlags.orderDetailsShowFooterOrderId !== undefined) {
      featureFlags.orderDetailsShowFooterOrderId =
        configFlags.orderDetailsShowFooterOrderId;
    }
    if (configFlags.contestingEnabled !== undefined) {
      featureFlags.contestingEnabled = configFlags.contestingEnabled;
    }
    if (configFlags.downloadReceiptEnabled !== undefined) {
      featureFlags.downloadReceiptEnabled = configFlags.downloadReceiptEnabled;
    }

    return featureFlags;
  }
}

const Singleton = new EnvironmentFlavor();
export default Singleton;
