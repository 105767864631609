import { useContext } from "react";
import { MasterContext } from "src/lib/masterContext";
import ReceiptDetailSection, {
  DetailItem,
} from "../../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../../lib/i18n";
import { formatTaxName } from "../../../../lib/utils";
import { useReceiptOrder } from "../../ReceiptContext";
import { useCurrencyFormatter } from "src/lib/hooks/useCurrencyFormatter";
import { ChargeDataBlockSettings, SubtotalBlockSettings, TaxBlockSettings, TaxCRVBlockSettings, TotalBlockSettings } from "../structure";

export type ProductChargeBlockProps = {
  blockSettings: ChargeDataBlockSettings
}

export const ProductChargeDataBlock = (props: ProductChargeBlockProps) => {
  const {blockSettings} = props
  return (
    <>
      <SubtotalBlock blockSettings={blockSettings} />
      <TaxCRVBlock blockSettings={blockSettings} />
      <TaxBlock blockSettings={blockSettings} />
      <TotalBlock blockSettings={blockSettings} />
    </>
  );
};

export type SubtotalBlockProps = {
  blockSettings: SubtotalBlockSettings
}

export const SubtotalBlock = (props: SubtotalBlockProps) => {
  const {blockSettings} = props

  const order = useReceiptOrder();
  const {
    featureFlags: { productsTaxEnabled },
  } = useContext(MasterContext);

  const {formatCurrency} = useCurrencyFormatter(order.currencyCode, blockSettings.currencyFormat)

  if (!productsTaxEnabled || !order.subtotalPrice) {
    return null;
  }

  return (
    <ReceiptDetailSection
      data={[
        {
          title: translate("receiptDetailScreen.subtotal") as string,
          value: formatCurrency(order.subtotalPrice),
        },
      ]}
    />
  );
};

export type TaxCRVBlockProps = {
  blockSettings: TaxCRVBlockSettings
}

export const TaxCRVBlock = (props: TaxCRVBlockProps) => {
  const {blockSettings} = props

  const order = useReceiptOrder();
  const {
    featureFlags: { productsTaxEnabled },
  } = useContext(MasterContext);

  const {formatCurrency} = useCurrencyFormatter(order.currencyCode, blockSettings.currencyFormat)

  if (!productsTaxEnabled || !order.totalCRVTax) {
    return null;
  }

  return (
    <ReceiptDetailSection
      data={[
        {
          title: translate("receiptDetailScreen.taxCRV") as string,
          value: formatCurrency(order.totalCRVTax),
        },
      ]}
    />
  );
};

export type TaxBlockProps = {
  blockSettings: TaxBlockSettings
}

export const TaxBlock = (props: TaxBlockProps) => {
  const {blockSettings} = props

  const order = useReceiptOrder();
  const {
    featureFlags: { productsTaxEnabled, hideTaxBreakdown },
  } = useContext(MasterContext);

  const {formatCurrency} = useCurrencyFormatter(order.currencyCode, blockSettings.currencyFormat)

  if (!productsTaxEnabled) {
    return null;
  }

  const chargeData: DetailItem[] = [];
  chargeData.push({
    title: translate("receiptDetailScreen.tax") as string,
    value: formatCurrency(order.totalTax),
  });

  if (!hideTaxBreakdown && order.taxBreakdown) {
    for (const item of order.taxBreakdown) {
      chargeData.push({
        title: formatTaxName(item.name, item.rate, 0),
        value: formatCurrency(item.amount),
        leftViewStyle: { paddingLeft: "1em" },
      });
    }
  }

  return <ReceiptDetailSection data={chargeData} />;
};

export type TotalBlockProps = {
  blockSettings: TotalBlockSettings
}

export const TotalBlock = (props: TotalBlockProps) => {
  const order = useReceiptOrder();
  const {blockSettings} = props
  const {formatCurrency} = useCurrencyFormatter(order.currencyCode, blockSettings.currencyFormat)

  return (
    <ReceiptDetailSection
      data={[
        {
          title: translate("receiptDetailScreen.total") as string,
          value: formatCurrency(order.totalPrice),
          valueTall: true,
        },
      ]}
    />
  );
};
