import { dpToPx } from "src/styles";
import { EmptySpaceBlockSettings } from "../structure";

export type EmptySpaceBlockProps = {
  blockSettings: EmptySpaceBlockSettings
}

export function EmptySpaceBlock(props: EmptySpaceBlockProps) {
  const {blockSettings} = props

  const spaceMap = {
    sm: dpToPx(8),
    md: dpToPx(16),
    lg: dpToPx(32),
  };

  return <div style={{ marginBottom: spaceMap[blockSettings.size ?? "md"] }} />;
}